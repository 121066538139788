import React from "react"
import {useDispatch} from "react-redux"
import {setPage} from "../reducers/pageReducer"
import {
  Typography,
  Button,
  Box,
} from "@material-ui/core"
// import postActiveCampaign from "../services/emailService"

const Start = () => {
  const dispatch = useDispatch()

  const handleClick = (event) => {
    event.preventDefault()
    dispatch(setPage("question"))
  }

  // const randomEmail = () => {
    // const rand = Math.floor(Math.random() * 1000)
    // return `test${rand}@example.com`
  // }

  // const testPost = (event) => {
  //   event.preventDefault()
  //   postActiveCampaign({
  //     name: "test delete",
  //     email: "kipiy59028@tibui.com",
  //     skill: "Beginner",
  //     playstyle: "Aggressive",
  //     discipline: "Singles",
  //     age: "25-34",
  //     gender: "Male",
  //     racketNum: 1
  //   })
  // }
  return (
    <>
      <Typography variant="h2" style={{textAlign: "center"}}>
        Find Your Perfect Badminton Racket
      </Typography>
      <Typography variant="body1" style={{textAlign: "center"}}>
        Just answer a few questions about your badminton game, and we'll do the rest!
      </Typography>
      <div>
        <img 
          src="https://i.imgur.com/LdRZbhn.jpg" 
          alt="badminton shop"
          style={{display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
            padding: 20
          }}
        />
      </div>
      
      <Box 
        display="flex" 
        width="100%" height={80} 
        alignItems="center"
        justifyContent="center"
      >
        <Box //nested box so i can make the button full width
          display="flex" 
          width="70%" height={80} 
          alignItems="center"
          justifyContent="center"
        >
          <Button 
            onClick={handleClick} 
            variant="contained" 
            color="primary" width={200} 
            size="large" 
            fullWidth={true}>
            Start
          </Button>

          {/* <Button onClick={testPost}>
            test api
          </Button> */}
        </Box>
      </Box>
    </>

  )
}

export default Start