import React from "react"
import Question from "../components/Question"
import Options from "../components/Options"
import Submit from "../components/Submit"
// import Results from "../components/Results"
import Start from "../components/Start"
import Loading from "../components/Loading"
import ResultEmail from "../components/ResultEmail"
import {useSelector} from "react-redux"
import {
  Grid,
  Paper
} from "@material-ui/core"


const PageSelector = () => {
  const page = useSelector(state => state.page)

  if (page === "start") {
    return <Start />
  } else if (page === "question") {
    return (
      <>
        <Grid container style={{paddingTop: "10%"}}>
          <Grid item xs="auto" sm={1} md={1}>
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <Paper style={{padding: 50}}>
              <Question />
              <Options />
            </Paper>
          </Grid>
          <Grid item xs="auto" sm={1} md={1}>
          </Grid>
        </Grid>
      </>
    )
  } else if (page === "submit") {
    return <Submit />
  } else if (page === "loading") {
    return <Loading />
  } else if (page === "resultemail") {
    return <ResultEmail />
  }
}

export default PageSelector