import { Container } from "@material-ui/core"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import PageSelector from "./components/PageSelector"
import Analytics from "./components/Analytics"
import Results from "./components/Results"

// const Test = () => {
//   const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
//   console.log("dklsjfd")
//   return (
//     <>
//       {/* {nums.map((num) => {const base = atob(num)
//       return (
//         <a href={`http://localhost:3000/${base}`}>test</a>
//       ))} */}

//       {nums.map((num) => {
//         const base = btoa(num)
//         return (
//           <div>
//             {num}{" "}
//             <a
//               href={`https://racket-quiz.herokuapp.com/#/results/${base}`}
//             >{`https://racket-quiz.herokuapp.com/#/results/${base}`}</a>
//           </div>
//         )
//       })}
//     </>
//   )
// }

const App = () => {
  return (
    //set up route for results, but scrapped it and just used exist CF landing
    //left it here if i move off CF in the future
    <Container>
      <Router>
        <Switch>
          <Route path="/results/:id">
            <Results />
          </Route>

          <Route path="/analytics">
            <Analytics />
          </Route>
          {/* <Route path="/test">
            <Test />
          </Route> */}
          <Route path="/">
            <PageSelector />
          </Route>
        </Switch>
      </Router>
    </Container>
  )
}

export default App
