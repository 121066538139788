export const addAnswer = (answer) => {
  return {
    type: "ADD_ANSWER",
    answer
  }
}

export const resetAnswer = () => {
  return {
    type: "RESET_ANSWERS"
  }
}

// const initialObject = {
//   answerOne: null,
//   answerTwo: null,
//   answerThree: null,
//   answerFour: null,
// }

const answerReducer = (state = [], action) => {
  switch(action.type) {
    case "ADD_ANSWER":
      return state.concat(action.answer)
    case "RESET_ANSWERS":
      return []
    default:
      return state
  }
}

export default answerReducer