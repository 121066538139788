import axios from "axios"

const baseUrl = process.env.REACT_APP_API

const postActiveCampaign = async (contact) => {
  const response = await axios.post(`${baseUrl}/contact`, contact)
  return response.data
}

export default postActiveCampaign
