import React from "react"
import {useSelector} from "react-redux"
import {
  Typography,
} from "@material-ui/core"

const Question = () => {
  const question = useSelector(state => state.question)
  return (
    <>
      <Typography variant="h4"  style={{textAlign: "center", marginBottom: 20}}>
        {question.question}
      </Typography>
    </>
  )
}

export default Question