import React from "react"
import {useSelector, useDispatch} from "react-redux"
import {nextQuestion, resetQuestion} from "../reducers/questionReducer"
import {addAnswer} from "../reducers/answerReducer"
import {setPage} from "../reducers/pageReducer"
import {Button, Box} from "@material-ui/core"

const Options = () => {
  const dispatch = useDispatch()
  const question = useSelector(state => state.question)
  const options = question.options
  
  const handleNextClick = (event, option) => {
    event.preventDefault()
    dispatch(addAnswer(option))
    dispatch(nextQuestion(question.num))
  }

  const handleFinalClick = (event, option) => {
    event.preventDefault()
    dispatch(addAnswer(option))
    dispatch(resetQuestion())
    dispatch(setPage("loading"))
  }

  const button = (option) => {
    if (question.num !== 5) {
      return (
        <Button 
          key={option} 
          variant="outlined" 
          color="primary"
          style={{align: "center", marginLeft: 10, marginRight: 10}}
          onClick={(event) => handleNextClick(event, option)}
          fullWidth={true}
        > 
          {option}
        </Button>
      )
    } else {
      return (
        <Button 
          key={option}
          variant="outlined" 
          color="primary"
          style={{align: "center", marginLeft: 10, marginRight: 10}}
          onClick={(event) => handleFinalClick(event, option)}
        >
          {option}
        </Button>
      )
    }
  }

  return (
    <div>
      <Box display="flex" 
        width="100%" height={80} 
        alignItems="center"
        justifyContent="center"
      >
        {options.map(o => button(o))}
      </Box>
    </div>
  )
}

export default Options