const rackets = [
  {
    name: "Yonex Voltric 21DG Slim",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-voltric-21dg-slim-badminton-racket-dark-gun",
    img: "https://i.imgur.com/zQ4dO85.png",
    weight: "3U",
    flex: "flexible",
    balance: "head heavy",
    num: 1,
  },

  {
    name: "Forza Power 376",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/fz-forza-power-376-black-green-badminton-racket",
    img: "https://i.imgur.com/wflvSFN.png",
    weight: "3U",
    flex: "flexible",
    balance: "even balanced",
    num: 2,
  },
  {
    name: "Yonex Nanoray 20",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-nanoray-20-badminton-racket-cystal-black-ocean-blue",
    img: "https://i.imgur.com/eSLW0ni.png",
    weight: "3U",
    flex: "flexible",
    balance: "head light",
    num: 3,
  },
  {
    name: "Yonex Astrox 77",
    url:
      "https://badmintonhq.co.uk/products/yonex-astrox-77-badminton-racket-blue-3u",
    img: "https://i.imgur.com/fsNISJg.png",
    weight: "3U",
    flex: "mid-flex",
    balance: "head heavy",
    num: 4,
  },
  {
    name: "Yonex Duora 7",
    url:
      "https://badmintonhq.co.uk/products/yonex-duora-7-badminton-racket-dark-gun",
    img: "https://i.imgur.com/fGGDcSb.png",
    weight: "3U",
    flex: "mid-flex",
    balance: "even balanced",
    num: 5,
  },
  {
    name: "Yonex Nanoray I-Speed",
    url:
      "https://badmintonhq.co.uk/products/yonex-nanoray-i-speed-badminton-racket-bright-orange",
    img: "https://i.imgur.com/HK8GMO1.png",
    weight: "3U",
    flex: "mid-flex",
    balance: "head light",
    num: 6,
  },
  {
    name: "Yonex Astrox 99",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-astrox-99-badminton-racket-orange",
    img: "https://i.imgur.com/X1AOYb7.png",
    weight: "3U",
    flex: "stiff",
    balance: "head heavy",
    num: 7,
  },
  {
    name: "Yonex Arcsaber 11",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-arcsaber-11-badminton-racket",
    img: "https://i.imgur.com/g63hrta.png",
    weight: "3U",
    flex: "stiff",
    balance: "even balanced",
    num: 8,
  },
  {
    name: "Yonex Nanoray 900",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-nanoray-900-badminton-racket-blue-navy",
    img: "https://i.imgur.com/8zkiDPw.png",
    weight: "3U",
    flex: "stiff",
    balance: "head light",
    num: 9,
  },
  {
    name: "Yonex Voltric 0F",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-voltric-0f-black-white-red-badminton-racket",
    img: "https://i.imgur.com/kCFAgPl.png",
    weight: "4U",
    flex: "flexible",
    balance: "head heavy",
    num: 10,
  },
  {
    name: "FZ Forza Power 276",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/fz-forza-power-276-silver-blue-badminton-racket",
    img: "https://i.imgur.com/ANsZrc1.png",
    weight: "4U",
    flex: "flexible",
    balance: "even balanced",
    num: 11,
  },
  {
    name: "Yonex Nanoray 10F",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-nanoray-10f-badminton-racket-black-blue",
    img: "https://i.imgur.com/fR5usGv.png",
    weight: "4U",
    flex: "flexible",
    balance: "head light",
    num: 12,
  },
  {
    name: "Yonex Astrox 7",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-astrox-7-badminton-racket-black-orange",
    img: "https://i.imgur.com/yh4fkO6.png",
    weight: "4U",
    flex: "mid-flex",
    balance: "head heavy",
    num: 13,
  },
  {
    name: "FZ Forza Light 1",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/fz-forza-light-1-black-blue-badminton-racket",
    img: "https://i.imgur.com/T86umhy.png",
    weight: "4U",
    flex: "mid-flex",
    balance: "even balanced",
    num: 14,
  },
  {
    name: "Yonex Nanoflare 700",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-nanoflare-700-badminton-racket-blue-green",
    img: "https://i.imgur.com/meFfCOd.png",
    weight: "4U",
    flex: "mid-flex",
    balance: "head light",
    num: 15,
  },
  {
    name: "Yonex Astrox 99",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-astrox-99-badminton-racket-orange",
    img: "https://i.imgur.com/tyn1Pra.png",
    weight: "4U",
    flex: "stiff",
    balance: "head heavy",
    num: 16,
  },
  {
    name: "Yonex Duora 10",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-duora-10-badminton-racket-blue-orange",
    img: "https://i.imgur.com/iZMApvQ.png",
    weight: "4U",
    flex: "stiff",
    balance: "even balanced",
    num: 17,
  },
  {
    name: "Yonex Nanoray 800",
    url:
      "https://badmintonhq.co.uk/discount/swift5?redirect=/products/yonex-nanoray-800-badminton-racket-poinsettia-red",
    img: "https://i.imgur.com/UGR9OFD.png",
    weight: "4U",
    flex: "stiff",
    balance: "head light",
    num: 18,
  },
]

export default rackets
