import React from "react"
import { useSelector } from "react-redux"
import { Grid, Typography, Paper } from "@material-ui/core"
import { useParams } from "react-router"
import calculateRacket from "../helpers"
import rackets from "../data/rackets"
import {
  beginner,
  intermediate,
  singles,
  doubles,
  aggressive,
  balanced,
  defensive,
  advanced,
} from "../data/copy"

const Results = () => {
  const { id } = useParams()
  const racketNum = atob(id)
  const racket = rackets.find((r) => r.num === Number(racketNum))

  console.log(racket)

  let weightCopy
  let flexCopy
  let balanceCopy

  if (racket.weight === "3U") {
    weightCopy = singles
  } else if (racket.weight === "4U") {
    weightCopy = doubles
  }

  if (racket.flex === "stiff") {
    flexCopy = advanced
  } else if (racket.flex === "mid-flex") {
    flexCopy = intermediate
  } else if (racket.flex === "flexible") {
    flexCopy = beginner
  }

  if (racket.balance === "head heavy") {
    balanceCopy = aggressive
  } else if (racket.balance === "even balanced") {
    balanceCopy = balanced
  } else if (racket.balance === "head light") {
    balanceCopy = defensive
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h2">Your Personalised Racket</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography
            body="body1"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            {weightCopy}
            {flexCopy}
            {balanceCopy}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Paper elevation={3} style={{ padding: 10 }}>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {racket.name}
            </Typography>
            <img src={racket.img} alt={racket.name} width="100%" />

            <Typography variant="body1">
              The {racket.name} is a {racket.weight}, {racket.flex},{" "}
              {racket.balance} racket. Perfectly suited for you!
            </Typography>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <a href={racket.url} target="_blank" rel="noreferrer">
                Purchase here
              </a>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default Results
