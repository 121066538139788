const questionList = [
  {
    question: "What is your skill level?",
    options: [
      "Beginner",
      "Intermediate",
      "Advanced"
    ],
    num: 1
  },
  {
    question: "How would you describe your playstyle?",
    options: [
      "Aggressive",
      "Balanced",
      "Defensive"
    ],
    num: 2
  },
  {
    question: "Do you prefer singles or doubles?",
    options: [
      "Singles",
      "Doubles",
    ],
    num: 3
  },
  {
    question: "What is your age?",
    options: [
      "18-",
      "18-24",
      "25-34",
      "35-44",
      "45-54",
      "55+"
    ],
    num: 4
  },
  {
    question: "What is your gender?",
    options: [
      "Male",
      "Female",
      "Prefer not to say"
    ],
    num: 5
  }
]


const questionReducer = (state = questionList[0], action) => {
  switch (action.type) {
    case "SET_QUESTION":
      return action.data
    case "RESET":
      return null
    default:
      return state
  }
}

export const nextQuestion = (currentNum) => {
  const nextIndex = currentNum
  const question = questionList[nextIndex]
  return {
    type: "SET_QUESTION",
    data: question
  }
}

export const resetQuestion = () => {
  return {
    type: "RESET"
  }
}

export default questionReducer