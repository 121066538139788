export const setPage = (page) => {
  return {
    type: "SET_PAGE",
    page: page
  }
}

const pageReducer = (state="start", action) => {
  switch (action.type) {
    case "SET_PAGE":
      return action.page 
    default:
      return state
  }
}

export default pageReducer