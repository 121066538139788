import React, {useEffect, useState} from "react"
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core"
import {useDispatch} from "react-redux"
import {setPage} from "../reducers/pageReducer"

const Loading = () => {
  const dispatch = useDispatch()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 300)

    return () => {
      clearInterval(timer);
    }
  }, [])

  if (progress === 100) {
    setTimeout(() => { 
      dispatch(setPage("submit"))
    }, 200)
  }

  return (
    <>
      <Box 
        position="relative" 
        display="inline-flex" 
        alignItems="center"
        justifyContent="center"
        width="100%" height={400}
      >
        <CircularProgress variant="static" value={progress} size={200}/>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" component="div" color="textSecondary">{`${Math.round(
            progress,
          )}%`}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default Loading