import axios from "axios"

const baseUrl = process.env.REACT_APP_API

const getData = async () => {
  const response = await axios.get(`${baseUrl}/data`)

  return response.data
}

export default getData
