import React, { useState, useEffect } from "react"
import {
  LineChart,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
} from "recharts"
import axios from "axios"

const Chart = ({ contacts }) => {
  let data = []

  contacts.map((contact) => {
    const day = contact.date.slice(0, 10)
    if (data.find((entry) => entry["date"] === day)) {
      const currentObject = data.find((entry) => entry["date"] === day)
      currentObject.value += 1
    } else {
      const object = {
        date: day,
        value: 1,
      }
      data = data.concat(object)
    }
  })

  console.log(data)
  return (
    <LineChart
      width={1000}
      height={400}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      style={{ marginTop: 20 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line dataKey="value" type="monotone" stroke="#8884d8" />
    </LineChart>
  )
}

export default Chart
