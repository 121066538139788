import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore, combineReducers} from "redux"
import {Provider} from "react-redux"

import questionReducer from "./reducers/questionReducer"
import answerReducer from "./reducers/answerReducer"
import pageReducer from "./reducers/pageReducer"

const reducer = combineReducers({
  question: questionReducer,
  answers: answerReducer,
  page: pageReducer,
})

const store = createStore(reducer)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
