import React from "react"

export const beginner = (
  <>
    <b>As a beginner player,</b> you want a flexible racket. Since you won’t be
    able to generate as much racket head speed as an advanced player, you have
    less force. The extra bend in a will assist you in generating power.
    <br />
    <br />
    The flexibility of the shaft determines how much the shaft will bend during
    a swing - flexible rackets will bend a lot, and stiff rackets won’t bend
    much.
    <br />
    <br />
    Playing with the right flex is crucial to generating “whip” in your shots,
    otherwise you lose a lot of power and control. You can think of it like
    gears on a bike. A low gear (flexible racket) is easier to pedal but doesn’t
    go as fast. A high gear (stiff racket), requires more force for the rider,
    but goes much faster.
    <br />
    <br />
  </>
)

export const intermediate = (
  <>
    <b>As an intermediate player,</b> you’re best served with a mid-flex racket.
    This allows you to generate more “whip” and power than a flexible racket,
    while still being more forgiving than a stiff racket which can be very
    demanding.
    <br />
    <br />
    The flexibility of the shaft determines how much the shaft will bend during
    a swing - flexible rackets will bend a lot, and stiff rackets won’t bend
    much.
    <br />
    <br />
    Beginners use flexible rackets since they aren’t able to generate much
    racket head speed, and need the extra flexibility. Advanced players can
    generate much more force with efficient technique, so are able “whip”
    stiffer rackets.
    <br />
    <br />
    Playing with the right flex is crucial to generating “whip” in your shots,
    otherwise you lose a lot of power and control. You can think of it like
    gears on a bike. A low gear (flexible racket) is easier to pedal but doesn’t
    go as fast. A high gear (stiff racket), requires more force for the rider,
    but goes much faster.
    <br />
    <br />
  </>
)

export const advanced = (
  <>
    <b>As an advanced player,</b> you want a stiff racket. Since you’re able to
    generate a lot of racket head speed, you’ll be able to generate enough force
    to “whip” to tame even a stiff racket. This will give you the most power and
    control.
    <br />
    <br />
    The flexibility of the shaft determines how much the shaft will bend during
    a swing - flexible rackets will bend a lot, and stiff rackets won’t bend
    much.
    <br />
    <br />
    Playing with the right flex is crucial to generating “whip” in your shots,
    otherwise you lose a lot of power and control. You can think of it like
    gears on a bike. A low gear (flexible racket) is easier to pedal but doesn’t
    go as fast. A high gear (stiff racket), requires more force for the rider,
    but goes much faster.
    <br />
    <br />
  </>
)

export const singles = (
  <>
    <b>As a singles player,</b> you’ll be moving around all 4 corners of a court
    and have more time to prepare for each shot. This means you can use a
    heavier racket, which will give you more punch behind your swing.
    <br />
    <br />
    The tradeoff you make is that heavier rackets are less manoeuvrable, which
    makes it harder to play fast and flat rallies. But these exchanges are more
    commonly seen in doubles.
    <br />
    <br />
    Your ideal racket will be between 85-89g, known as “3U”.
    <br />
    <br />
  </>
)

export const doubles = (
  <>
    <b>As a doubles player,</b> you’ll be playing rallies that are fast and
    flat. A lighter racket is better, since the manoeuvrability will give you
    the ability to quickly react and respond to the fast demands of doubles.
    <br />
    <br />
    The tradeoff you make is that there’s less “punch” to your shots, as your
    racket has less weight to put behind the shuttle. But this is not as
    important in doubles, and can be mitigated with a head heavy balance.
    <br />
    <br />
    Your ideal racket will be between 80-84g, known as “4U”.
    <br />
    <br />
  </>
)

export const aggressive = (
  <>
    <b>As an aggressive player,</b> you’ll want a racket that compliments your
    style and helps you generate more power in your smashes, and bury shuttles
    into the ground.
    <br />
    <br />
    Head heavy rackets do exactly that. By being balanced with more weight on
    the head of the racket, your swings generate more momentum and strike the
    shuttle harder.
    <br />
    <br />
    However, this balance can be harder to control and maenouver, so the trade
    off you make is a reduction in control with delicate shots. But boy do they
    pack a punch when you fire off that winning smash, which is perfect for you
    as an aggressive player.
    <br />
    <br />
  </>
)

export const balanced = (
  <>
    <b>As a balanced player,</b> your playstyle is a blend of aggressive and
    defensive play. You want a racket that empowers you to have this versatility
    in your game, while being strong all round.
    <br />
    <br />
    That’s exactly what an even balanced racket is for, were the weight is
    distributed between the head and the handle. While head heavy rackets pack
    more “oomph” and head light rackets are more agile - even balanced rackets
    sit between that range. This gives you the best of both worlds, and
    compliments both styles of play.
    <br />
    <br />
    The trade off is that an even balanced racket isn’t top of class at any one
    thing, instead being a jack of all trades. But for a balanced player, the
    versatility makes it a perfect compliment to your play style.
    <br />
    <br />
  </>
)

export const defensive = (
  <>
    <b>As a defensive player,</b> you’ll want a racket that compliments your
    style and helps you play shots with control, and develop a “brick-wall”
    defense.
    <br />
    <br />
    Head light rackets do exactly that. By being balanced with less weight at
    the head, the racket is much more manoeuvrable and allows the player to move
    the racket through the air with incredible agility.
    <br />
    <br />
    However, this balance means there’s less of an “oomph” behind your shots, so
    the trade off you make is reduced power in smashes and overhead shots. But
    the extra control and speed is results in incredibly consistent defensive
    play.
    <br />
    <br />
  </>
)
