import React, {useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import {setPage} from "../reducers/pageReducer"
import {Typography, Button, TextField, Grid, Box} from "@material-ui/core"
import { Alert } from '@material-ui/lab'
import postActiveCampaign from "../services/emailService"
import calculateRacket from "../helpers"

const Submit = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)

  const dispatch = useDispatch()
  const answers = useSelector(state => state.answers)
  const data = calculateRacket(answers)

  // const randomEmail = () => {
  //   const rand = Math.floor(Math.random() * 1000)
  //   return `test${rand}@example.com`
  // }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await postActiveCampaign({
        name,
        email,
        skill: answers[0],
        playstyle: answers[1],
        discipline: answers[2],
        age: answers[3],
        gender: answers[4],
        racketNum: data.racket.num
      })
      dispatch(setPage("resultemail"))
    } catch (error) {
      setError("please enter a valid name + email address")
      setTimeout(() => { 
        setError(null)
      }, 3000)
    }
  }

  // const testSubmit = (event) => {
  //   event.preventDefault()
  //   dispatch(setPage("loading"))
  //   postActiveCampaign({
  //     name: "test delete",
  //     email: randomEmail(),
  //     skill: answers[0],
  //     playstyle: answers[1],
  //     discipline: answers[2],
  //     age: answers[3],
  //     gender: answers[4],
  //     racketNum: data.racket.num
  //   })
  // }

  return (
    <>
      {(error &&
        <Alert severity="error">
          {error}
        </Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Typography variant="h2">
            All Done!
          </Typography>
          <img src="https://imgur.com/5ewzyS8.png" width="60%" alt="badminton racket"/>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <Typography variant="body1">
            Enter your details and we'll send your personalised racket straight to your inbox.
          </Typography>
        </Grid>
      </Grid>

      <Box 
          display="flex" 
          width="100%" height={200} 
          alignItems="center"
          justifyContent="center"
          >
              <form onSubmit={handleSubmit}>
                <div>
                  <TextField 
                    onChange={({target}) => setName(target.value)}
                    required
                    label="First Name"
                    fullWidth={true}
                  />
                </div>
                <div>
                <TextField 
                    onChange={({target}) => setEmail(target.value)}
                    required
                    label="Email"
                    style={{marginBottom: 10}}
                    fullWidth={true}
                  />
                </div>
                <Box //nested box so i can make the button full width
                  display="flex" 
                  width={500} height={80} 
                  // bgcolor="red"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button type="submit" variant="contained" color="primary" size="large" fullWidth={true}>
                    Show me my results!
                  </Button>
                </Box>
              </form>
          </Box>
      
      {/* <Button onClick={testSubmit} variant="outlined">
        use random info
      </Button> */}

    </>
  )
}

export default Submit