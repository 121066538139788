import {
  beginner,
  intermediate,
  singles,
  doubles,
  aggressive,
  balanced,
  defensive,
  advanced,
} from "./data/copy"
import rackets from "./data/rackets"

const calculateRacket = (answers) => {
  let weightCopy
  let flexCopy
  let balanceCopy

  const skill = answers[0]
  const playstyle = answers[1]
  const discipline = answers[2]

  let filteredRackets = rackets

  if (discipline === "Singles") {
    weightCopy = singles
    filteredRackets = filteredRackets.filter((f) => f.weight === "3U")
  } else if (discipline === "Doubles") {
    weightCopy = doubles
    filteredRackets = filteredRackets.filter((f) => f.weight === "4U")
  }

  if (skill === "Beginner") {
    flexCopy = beginner
    filteredRackets = filteredRackets.filter((f) => f.flex === "flexible")
  } else if (skill === "Intermediate") {
    flexCopy = intermediate
    filteredRackets = filteredRackets.filter((f) => f.flex === "mid-flex")
  } else if (skill === "Advanced") {
    flexCopy = advanced
    filteredRackets = filteredRackets.filter((f) => f.flex === "stiff")
  }

  if (playstyle === "Aggressive") {
    balanceCopy = aggressive
    filteredRackets = filteredRackets.filter((f) => f.balance === "head heavy")
  } else if (playstyle === "Balanced") {
    balanceCopy = balanced
    filteredRackets = filteredRackets.filter(
      (f) => f.balance === "even balanced"
    )
  } else if (playstyle === "Defensive") {
    balanceCopy = defensive
    filteredRackets = filteredRackets.filter((f) => f.balance === "head light")
  }
  const racket = filteredRackets[0]

  const returnedObject = {
    racket,
    weightCopy,
    flexCopy,
    balanceCopy,
  }
  return returnedObject
}

export default calculateRacket
