import React from "react"
import {
  Typography,
} from "@material-ui/core"

const ResultEmail = () => {
  return (
    <>
      <Typography variant="h2" style={{textAlign: "center", marginBottom: 20, marginTop: 40}}>
        Great! Please check your email for your personalised racket result.
      </Typography>
      <Typography variant="h4" style={{textAlign: "center"}}>
        If its not there, please wait up to 5m and check your spam folder.
      </Typography>
    </>
  )
}

export default ResultEmail