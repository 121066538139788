import React, { useState, useEffect } from "react"
import Chart from "./Chart"
import getData from "../services/dataService"
import { Link } from "react-router-dom"
import axios from "axios"

const Analytics = () => {
  const [data, setData] = useState(null)
  const [auth, setAuth] = useState(false)
  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API
    getData().then((response) => setData(response))

    const config = {
      headers: { Authorization: `bearer abc` },
    }

    // this should be a service
    axios
      .get(`${baseUrl}/contacts`, config)
      .then((response) => setContacts(response.data))
  }, [])

  const checkAuth = (target) => {
    if (target.value === process.env.REACT_APP_PASS) {
      setAuth(true)
    }
  }

  if (!auth) {
    return (
      <>
        <Link to="/">
          <div>Home</div>
        </Link>
        <input onChange={({ target }) => checkAuth(target)} />
      </>
    )
  }

  if (!data || !contacts) {
    return "loading"
  }

  const total = data.total

  const calcPercent = (num) => {
    const percent = (100 * num) / total
    return parseFloat(percent).toFixed(1) + "%"
  }

  return (
    <>
      <Link to="/">
        <div>Home</div>
      </Link>
      <div>Total: {data.total}</div>

      <div>
        <h2>Playstyle</h2>
      </div>
      <div>
        <div>Aggressive {calcPercent(data.aggressive)}</div>
      </div>
      <div>
        <div>Balanced {calcPercent(data.balanced)}</div>
      </div>
      <div>
        <div>Defensive {calcPercent(data.defensive)}</div>
      </div>

      <div>
        <h2>Skill</h2>
      </div>
      <div>
        <div>Advanced {calcPercent(data.advanced)}</div>
      </div>
      <div>
        <div>Intermediate {calcPercent(data.intermediate)}</div>
      </div>
      <div>
        <div>Beginner {calcPercent(data.beginner)}</div>
      </div>

      <div>
        <h2>Discipline</h2>
      </div>
      <div>
        <div>Singles {calcPercent(data.singles)}</div>
      </div>
      <div>
        <div>Doubles {calcPercent(data.doubles)}</div>
      </div>

      <div>
        <h2>Age</h2>
      </div>
      <div>
        <div>Under 18 {calcPercent(data.under18)}</div>
      </div>
      <div>
        <div>18-24 {calcPercent(data.age18to24)}</div>
      </div>
      <div>
        <div>24-35 {calcPercent(data.age25to34)}</div>
      </div>
      <div>
        <div>34-45 {calcPercent(data.age35to44)}</div>
      </div>
      <div>
        <div>44-54 {calcPercent(data.age45to54)}</div>
      </div>
      <div>
        <div>Over 55 {calcPercent(data.over55)}</div>
      </div>

      <div>
        <h2>Gender</h2>
      </div>
      <div>
        <div>Male {calcPercent(data.male)}</div>
      </div>
      <div>
        <div>Female {calcPercent(data.female)}</div>
      </div>
      <div>
        <div>Prefer not to say {calcPercent(data.prefNot)}</div>
      </div>
      <Chart contacts={contacts} />
    </>
  )
}

export default Analytics
